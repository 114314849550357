import styled from "styled-components";

const SectionStyled = styled.section`
  width: 100%;
  height: ${({ height }) => height ?? "auto"};
  background-color: ${({ theme, backgroundcolor }) => backgroundcolor ?? theme?.colors?.backgroundPrimary};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionContentStyled = styled.section`
  width: 1300px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: ${({ minheight }) => minheight ?? "1px"};
  max-width: 100%;
`;

export { SectionStyled, SectionContentStyled };
