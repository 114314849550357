import { ConfigProvider } from "antd";
import { useTheme } from "styled-components";

const AntdThemeProvider = ({ children }) => {
  const theme = useTheme();

  const antdTheme = {
    token: {
      colorPrimary: theme?.colors?.primary,
    },
  };
  return <ConfigProvider theme={antdTheme}>{children}</ConfigProvider>;
};

export { AntdThemeProvider };
