import styled from "styled-components";

const LinkStyled = styled.a`
  font-weight: ${({ strong, theme }) => (strong ? theme?.sizes?.bold : 400)};
  font-size: ${({ theme, level }) => theme?.sizes?.[`font${level}`] ?? theme?.sizes?.font1}px;
  color: ${({ theme, color }) => color ?? theme?.colors?.textLight};
  height: max-content;
  max-width: 100%;
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  position: relative;
  transition: color 600ms ease;
  display: flex;
  align-items: center;
  gap: 10px;

  & svg {
    transition: fill 600ms ease;
    fill: ${({ theme, color }) => color ?? theme?.colors?.textLight};
  }

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme?.colors?.primary};
  }

  &:hover svg {
    fill: ${({ theme }) => theme?.colors?.primary};
  }
`;
export { LinkStyled };
