import { ThemeProvider } from "styled-components";
import { theme } from "./constants/theme";
import { AntdThemeProvider } from "./contexts/antThemeProvider";
import Routes from "./routes/routes";
import GlobalStyle from "./styles";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AntdThemeProvider>
        <GlobalStyle />
        <Routes />
      </AntdThemeProvider>
    </ThemeProvider>
  );
}

export default App;
