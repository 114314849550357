import styled from "styled-components";

const directions = {
  ["vertical"]: "column",
  ["horizontal"]: "row",
};

const align_options = {
  ["vertical"]: "flex-start",
  ["horizontal"]: "center",
};

const SpaceStyled = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? directions[direction] : directions["horizontal"])};
  align-items: ${({ direction, align }) =>
    align ?? (direction ? align_options[direction] : align_options["horizontal"])};
  gap: ${({ size }) => size ?? 10}px;
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  justify-content: ${({ justify }) => justify ?? "normal"};

  width: ${({ width }) => (width ? `${width}` : "auto")};
  height: ${({ height }) => (height ? `${height}` : "auto")};
  max-width: 100%;
`;
export { SpaceStyled };
