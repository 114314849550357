import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import { Section } from "../../components/Section";
import { Header } from "../../components/Header";
import { CustomCol, CustomRow } from "../../components/Grid";
import { Text, Title } from "../../components/Typography";
import { Space } from "../../components/Space";
import { Link } from "../../components/Link";
import { Image } from "../../components/Image";
import IconCustom from "../../components/Icon";
import descriptions from "../../constants/descriptions.json";
import { Footer } from "../../components/Footer";
import { Carousel } from "../../components/Carousel";

const AboutCompanny = () => {
  const theme = useTheme();
  const paddingCol = theme?.sizes?.padding * 2;
  const fontTitle = 55;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const styles = {
    section: {
      paddingBlock: 80,
    },
    row: {
      flexGrow: 1,
    },
    backgroundLogo: {
      background: `linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(${theme?.images?.logo}) `,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center center",
    },
  };

  return (
    <React.Fragment>
      <Section style={styles?.backgroundLogo} backgroundcolor={theme?.colors?.backgroundSecondary} minheight="100dvh">
        <Header activateKey="about" />
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={5} xxl={5}>
            <Space direction="vertical" size={40}>
              <Space direction="vertical" size={0}>
                <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
                  {descriptions?.companyIntroduciton?.label}
                </Text>
                <Title size={fontTitle} strong>
                  {descriptions?.companyIntroduciton?.title}
                </Title>
              </Space>

              <Text level={1}>{descriptions?.companyIntroduciton?.description}</Text>

              <Space size={20}>
                <Link href={descriptions?.socialLinks?.instagram}>
                  <IconCustom size={theme?.sizes?.title1} iconName="InstagramLogo" weight="regular" />
                </Link>
                <Link href={descriptions?.socialLinks?.facebook}>
                  <IconCustom size={theme?.sizes?.title1} iconName="FacebookLogo" weight="regular" />
                </Link>
                <Link href={descriptions?.socialLinks?.whatsapp}>
                  <IconCustom size={theme?.sizes?.title1} iconName="WhatsappLogo" weight="regular" />
                </Link>
              </Space>
            </Space>
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={7} xxl={7}>
            <Image src={theme?.images?.leandroCompany} width="100%" />
          </CustomCol>
        </CustomRow>
      </Section>

      <Section sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Carousel autoplay>
              <Image src={theme?.images?.company1} maxheight="450px" width="100%" />
              <Image src={theme?.images?.company2} maxheight="450px" width="100%" />
              <Image src={theme?.images?.company3} maxheight="450px" width="100%" />
              <Image src={theme?.images?.company4} maxheight="450px" width="100%" />
            </Carousel>
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space direction="vertical" size={20}>
              <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
                {descriptions?.companyAbout?.label}
              </Text>
              <Title level={4} strong>
                {descriptions?.companyAbout?.title}
              </Title>
              <Text level={4}>{descriptions?.companyAbout?.description}</Text>
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section sectionStyle={styles?.section} backgroundcolor={theme?.colors?.backgroundSecondary}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space direction="vertical" size={20}>
              <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
                {descriptions?.companyDirector?.label}
              </Text>
              <Title level={4} strong>
                {descriptions?.companyDirector?.title}
              </Title>
              <Text level={4}>{descriptions?.companyDirector?.description}</Text>
            </Space>
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Image src={theme?.images?.leandro} width="100%" />
          </CustomCol>
        </CustomRow>
      </Section>

      <Footer />
    </React.Fragment>
  );
};

export default AboutCompanny;
