import styled from "styled-components";

const ButtonStyled = styled.button`
  border-radius: ${({ theme }) => theme?.sizes?.radius}px;
  padding: ${({ theme }) => theme?.sizes?.padding}px;
  gap: ${({ theme }) => theme?.sizes?.padding}px;
  width: ${({ width }) => (width ? `${width}px` : "max-content")};
  height: ${({ height }) => (height ? `${height}px` : "max-content")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  font-family: Arial;

  transition: box-shadow 100ms ease, opacity 800ms ease;
  &:hover {
    opacity: ${({ disabled }) => (disabled ? 0.7 : 0.8)};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  &:active {
    box-shadow: ${({ disabled, theme }) => (disabled ? "none" : `0 0 0 0.25rem ${theme?.colors?.primary}50`)};
  }
`;

const ButtonPrimaryStyled = styled(ButtonStyled)`
  background-color: ${({ theme }) => theme?.colors?.primary};
`;

const ButtonSecondaryStyled = styled(ButtonStyled)`
  background-color: ${({ theme }) => theme?.colors?.primaryLight};
`;

const ButtonTextStyled = styled(ButtonStyled)`
  background-color: transparent;
  color: ${({ theme, color }) => color ?? theme?.colors?.primary};
`;

export { ButtonPrimaryStyled, ButtonSecondaryStyled, ButtonTextStyled };
