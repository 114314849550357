import styled from "styled-components";

const TextStyled = styled.span`
  font-weight: ${({ strong, theme }) => (strong ? theme?.sizes?.bold : 400)};
  font-size: ${({ theme, level, size }) => size ?? theme?.sizes?.[`font${level}`] ?? theme?.sizes?.font4}px;
  color: ${({ theme, color }) => color ?? theme?.colors?.text};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  letter-spacing: ${({ letterSpacing }) => (letterSpacing ? `${letterSpacing}px` : "normal")};
  position: relative;
  line-height: ${({ theme, lineHeight }) => lineHeight ?? theme?.sizes?.lineHeight}em;
  text-align: ${({ align }) => align ?? "left"};
  white-space: pre-line;

  &::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    height: 2px;
    width: 50px;
    display: ${({ lineright }) => (lineright ? "block" : "none")};
    background-color: ${({ theme, color }) => color ?? theme?.colors?.text};
    transform: translateY(-50%);
  }
`;

const TitleStyled = styled(TextStyled)`
  font-size: ${({ theme }) => theme?.sizes?.title}px;
  font-size: ${({ theme, level, size }) => size ?? theme?.sizes?.[`title${level}`] ?? theme?.sizes?.title4}px;
`;

export { TextStyled, TitleStyled };
