import React from "react";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import { ContainerSliderStyled } from "./styles";
import { useTheme } from "styled-components";

const SliderImage = ({ image1, image2, width }) => {
  const theme = useTheme();

  return (
    <ContainerSliderStyled width={width}>
      <ReactBeforeSliderComponent
        delimiterColor={theme?.colors?.primary}
        className="teste"
        delimiterIconStyles={() => <span>"Tese"</span>}
        firstImage={{
          imageUrl: image1,
        }}
        secondImage={{
          imageUrl: image2,
        }}
      />
    </ContainerSliderStyled>
  );
};

export { SliderImage };
