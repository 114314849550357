import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import { Section } from "../../components/Section";
import { Header } from "../../components/Header";
import { CustomCol, CustomRow } from "../../components/Grid";
import { Text, Title } from "../../components/Typography";
import { Space } from "../../components/Space";
import IconCustom from "../../components/Icon";
import descriptions from "../../constants/descriptions.json";
import { Footer } from "../../components/Footer";
import { Tag } from "../../components/Tag";
import { FormContact } from "../../components/FormContact";
import { Link } from "../../components/Link";

const Contact = () => {
  const theme = useTheme();
  const paddingCol = theme?.sizes?.padding * 2;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const styles = {
    section: {
      paddingBlock: 80,
    },
    row: {
      flexGrow: 1,
    },
    backgroundLogo: {
      background: `linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(${theme?.images?.logo}) `,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center center",
    },
  };

  return (
    <React.Fragment>
      <Section backgroundcolor={theme?.colors?.backgroundSecondary} minheight="70dvh">
        <Header activateKey="contact" />
        <CustomRow style={styles?.row} align="center" justify="center">
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <Space direction="vertical" size={40} align="center">
              <Title align="center" size={45} level={1} strong>
                {descriptions?.contact?.title}
              </Title>
              <Text align="center" level={1}>
                {descriptions?.contact?.description}
              </Text>
              <Space align="center" size={20}>
                <Link href={descriptions?.socialLinks?.instagram}>
                  <IconCustom size={theme?.sizes?.title1} iconName="InstagramLogo" weight="regular" />
                </Link>
                <Link href={descriptions?.socialLinks?.facebook}>
                  <IconCustom size={theme?.sizes?.title1} iconName="FacebookLogo" weight="regular" />
                </Link>
                <Link href={descriptions?.socialLinks?.whatsapp}>
                  <IconCustom size={theme?.sizes?.title1} iconName="WhatsappLogo" weight="regular" />
                </Link>
              </Space>
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section backgroundcolor={theme?.colors?.primary}>
        <CustomRow style={styles?.row} align="center" justify="center">
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space size={20} wrap={0}>
              <Tag padding={20} radius={100} backgroundcolor={theme?.colors?.white}>
                <IconCustom color={theme?.colors?.primary} iconName="EnvelopeOpen" size={40} />
              </Tag>
              <Space direction="vertical" size={5}>
                <Text strong level={3} color={theme?.colors?.white}>
                  {descriptions?.contactItem?.title}
                </Text>
                <Text color={theme?.colors?.white} level={4}>
                  {descriptions?.contactItem?.description}
                </Text>
              </Space>
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section backgroundcolor={theme?.colors?.backgroundSecondary} minheight="70dvh">
        <CustomRow style={styles?.row} align="center" justify="center">
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <FormContact />
          </CustomCol>
        </CustomRow>
      </Section>

      <Footer />
    </React.Fragment>
  );
};

export default Contact;
