import React from "react";
import { Input } from "antd";

const formatMask = (value, maskType) => {
  switch (maskType) {
    case "cpf":
      return value
        ?.replace(/\D/g, "")
        ?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
        .substr(0, 14);
    case "cnpj":
      return value
        ?.replace(/\D/g, "")
        ?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        .substr(0, 18);
    case "celular":
      if (value?.replace(/\D/g, "")?.length <= 10) {
        return value
          ?.replace(/\D/g, "")
          ?.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3")
          .substr(0, 15);
      }
      return value
        ?.replace(/\D/g, "")
        ?.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3")
        .substr(0, 15);

    case "cpf_cnpj":
      if (value?.replace(/\D/g, "")?.length <= 11) {
        return value
          ?.replace(/\D/g, "")
          ?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
          .substr(0, 14);
      }
      return value
        ?.replace(/\D/g, "")
        ?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        .substr(0, 18);
    default:
      return value;
  }
};

const CustomInputMask = (props) => {
  const { maskType = "", ...otherProps } = props;

  const handleInputChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatMask(value, maskType);

    if (props.onChange) {
      props.onChange({
        ...e,
        target: {
          ...e.target,
          value: formattedValue,
        },
      });
    }
  };

  return <Input {...otherProps} onChange={handleInputChange} />;
};

export { CustomInputMask };
