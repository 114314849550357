import { ImageStyled } from "./styles";

const Image = ({ width, height, resize, src, style, maxheight, maxwidth }) => {
  return (
    <ImageStyled
      style={style}
      height={height}
      maxwidth={maxwidth}
      maxheight={maxheight}
      width={width}
      resize={resize}
      src={src}
    />
  );
};

export { Image };
