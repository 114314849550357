import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProductFitBox from "../pages/productFitBox";
import AboutCompanny from "../pages/company";
import Contact from "../pages/contact";

const router = createBrowserRouter([
  {
    path: "*",
    element: <ProductFitBox />,
  },
  {
    path: "/about",
    element: <AboutCompanny />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

const PublicRoutes = () => {
  return <RouterProvider router={router} />;
};

export default PublicRoutes;
