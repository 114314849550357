import { TextStyled, TitleStyled } from "./styles";

const Text = ({
  underline,
  strong,
  level,
  color,
  children,
  italic,
  style,
  letterSpacing,
  lineright,
  lineHeight,
  align,
  size,
}) => {
  return (
    <TextStyled
      level={level}
      align={align}
      size={size}
      lineright={lineright}
      lineHeight={lineHeight}
      color={color}
      letterSpacing={letterSpacing}
      style={style}
      underline={underline?.toString()}
      strong={strong?.toString()}
      italic={italic?.toString()}>
      {children}
    </TextStyled>
  );
};

const Title = ({
  underline,
  strong,
  color,
  children,
  italic,
  style,
  letterSpacing,
  level,
  lineright,
  lineHeight,
  align,
  size,
}) => {
  return (
    <TitleStyled
      lineHeight={lineHeight}
      align={align}
      style={style}
      size={size}
      lineright={lineright}
      level={level}
      letterSpacing={letterSpacing}
      color={color}
      underline={underline?.toString()}
      strong={strong?.toString()}
      italic={italic?.toString()}>
      {children}
    </TitleStyled>
  );
};

export { Text, Title };
