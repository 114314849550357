import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import { ButtonPrimary, ButtonSecondary, ButtonText } from "../../components/Buttons";
import { Section } from "../../components/Section";
import { Header } from "../../components/Header";
import { CustomCol, CustomRow } from "../../components/Grid";
import { Text, Title } from "../../components/Typography";
import { Space } from "../../components/Space";
import { Link } from "../../components/Link";
import { Image } from "../../components/Image";
import IconCustom from "../../components/Icon";
import { Carousel } from "../../components/Carousel";
import { Card } from "../../components/Card";
import { Tag } from "../../components/Tag";
import { Divider } from "../../components/Divider";
import { SliderImage } from "../../components/SliderImage";
import descriptions from "../../constants/descriptions.json";
import { Youtube } from "../../components/youtube";
import { Footer } from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import Pdf from "../../assets/documents/Revista.pdf";

const ProductFitBox = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const paddingCol = theme?.sizes?.padding * 2;
  const fontTitle = 55;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const styles = {
    section: {
      paddingBlock: 60,
    },
    row: {
      flexGrow: 1,
    },
    backgroundLogo: {
      background: `linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url(${theme?.images?.logo}) `,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center center",
    },
  };

  return (
    <React.Fragment>
      <Section style={styles?.backgroundLogo} backgroundcolor={theme?.colors?.backgroundSecondary} minheight="100dvh">
        <Header activateKey="product" />
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={5} xxl={5}>
            <Space direction="vertical" size={40}>
              <Space direction="vertical" size={0}>
                <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
                  {descriptions?.introduction?.label}
                </Text>

                <Title size={fontTitle} strong>
                  {descriptions?.introduction?.title}
                </Title>
              </Space>
              <Text level={1}>{descriptions?.introduction?.description}</Text>

              <Space>
                <ButtonPrimary
                  icon="ShoppingCartSimple"
                  text={descriptions?.introduction?.firstButton}
                  onClick={() => navigate("/contact")}
                  width={180}
                />
                <ButtonSecondary
                  onClick={() => window.open(descriptions?.introduction?.secondButtonLink)}
                  icon="ShoppingBagOpen"
                  text={descriptions?.introduction?.secondButton}
                  width={180}
                />
              </Space>

              <Space size={20}>
                <Link href={descriptions?.socialLinks?.instagram}>
                  <IconCustom size={theme?.sizes?.title1} iconName="InstagramLogo" weight="regular" />
                </Link>
                <Link href={descriptions?.socialLinks?.facebook}>
                  <IconCustom size={theme?.sizes?.title1} iconName="FacebookLogo" weight="regular" />
                </Link>
                <Link href={descriptions?.socialLinks?.whatsapp}>
                  <IconCustom size={theme?.sizes?.title1} iconName="WhatsappLogo" weight="regular" />
                </Link>
              </Space>
            </Space>
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={7} xxl={7}>
            <Image src={theme?.images?.produto} width="100%" />
          </CustomCol>
        </CustomRow>
      </Section>

      <Section backgroundcolor={theme?.colors?.backgroundSecondary} sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space direction="vertical" size={20}>
              <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
                {descriptions?.about?.label}
              </Text>
              <Title level={4} strong>
                {descriptions?.about?.title}
              </Title>

              <Text level={4}>{descriptions?.about?.description}</Text>
            </Space>
          </CustomCol>

          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Carousel autoplay>
              <Image src={theme?.images?.modelo3d2} width="100%" />
              <Image src={theme?.images?.modelo3d1} width="100%" />
              <Image src={theme?.images?.modelo3d3} width="100%" />
            </Carousel>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol}>
            <Space direction="vertical" size={40}>
              <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
                {descriptions?.models?.label}
              </Text>

              <Space justify="space-around" align="normal" width="100%" size={20}>
                <Card
                  bodyradius={theme?.sizes?.radius}
                  padding={theme?.sizes?.padding}
                  width={350}
                  image={theme?.images?.modelo1}>
                  <Space justify="space-between" height="100%" direction="vertical" size={10}>
                    <Space direction="vertical">
                      <Text level={1} strong color={theme?.colors?.white}>
                        {descriptions?.models?.first?.title}
                      </Text>
                      <Text color={theme?.colors?.white}>{descriptions?.models?.first?.description}</Text>
                    </Space>
                    <ButtonText
                      icon="ShoppingCartSimple"
                      iconDirection="left"
                      onClick={() => navigate("/contact")}
                      color={theme?.colors?.white}
                      text="Comprar Agora"
                    />
                  </Space>
                </Card>

                <Card
                  bodyradius={theme?.sizes?.radius}
                  padding={theme?.sizes?.padding}
                  width={350}
                  image={theme?.images?.modelo2}>
                  <Space justify="space-between" height="100%" direction="vertical" size={10}>
                    <Space direction="vertical">
                      <Text level={1} strong color={theme?.colors?.white}>
                        {descriptions?.models?.second?.title}
                      </Text>
                      <Text level={4} color={theme?.colors?.white}>
                        {descriptions?.models?.second?.description}
                      </Text>
                    </Space>

                    <ButtonText
                      icon="ShoppingCartSimple"
                      iconDirection="left"
                      color={theme?.colors?.white}
                      onClick={() => navigate("/contact")}
                      text="Comprar Agora"
                    />
                  </Space>
                </Card>

                <Card
                  bodyradius={theme?.sizes?.radius}
                  padding={theme?.sizes?.padding}
                  width={350}
                  image={theme?.images?.modelo3}>
                  <Space justify="space-between" height="100%" direction="vertical" size={10}>
                    <Space direction="vertical">
                      <Text level={1} strong color={theme?.colors?.white}>
                        {descriptions?.models?.third?.title}
                      </Text>
                      <Text color={theme?.colors?.white}>{descriptions?.models?.third?.description}</Text>
                    </Space>
                    <ButtonText
                      icon="ShoppingCartSimple"
                      iconDirection="left"
                      onClick={() => navigate("/contact")}
                      color={theme?.colors?.white}
                      text="Comprar Agora"
                    />
                  </Space>
                </Card>
              </Space>
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section backgroundcolor={theme?.colors?.primary} sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol}>
            <Space align="center" direction="vertical" size={40}>
              <Space justify="space-around" align="normal" width="100%" size={20}>
                <Space direction="vertical" width="300px" align="center" size={20}>
                  <Tag padding={35} radius={100} backgroundcolor={theme?.colors?.white}>
                    <IconCustom color={theme?.colors?.primary} iconName="Plug" size={60} />
                  </Tag>
                  <Text align="center" color={theme?.colors?.white} strong level={1}>
                    {descriptions?.benefits?.first?.title}
                  </Text>
                </Space>

                <Space direction="vertical" width="300px" align="center" size={20}>
                  <Tag padding={35} radius={100} backgroundcolor={theme?.colors?.white}>
                    <IconCustom color={theme?.colors?.primary} iconName="Hammer" size={60} />
                  </Tag>
                  <Text color={theme?.colors?.white} align="center" strong level={1}>
                    {descriptions?.benefits?.second?.title}
                  </Text>
                </Space>

                <Space direction="vertical" width="300px" align="center" size={20}>
                  <Tag padding={35} radius={100} backgroundcolor={theme?.colors?.white}>
                    <IconCustom color={theme?.colors?.primary} iconName="MagicWand" size={60} />
                  </Tag>
                  <Text color={theme?.colors?.white} align="center" strong level={1}>
                    {descriptions?.benefits?.third?.title}
                  </Text>
                </Space>
              </Space>
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol} sm={12}>
            <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
              {descriptions?.advantages?.label}
            </Text>
          </CustomCol>

          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space direction="vertical" size={40}>
              <Space size={20} wrap={0}>
                <Tag shadow={1} padding={20} radius={theme?.sizes?.radius * 2} backgroundcolor={theme?.colors?.primary}>
                  <IconCustom color={theme?.colors?.white} iconName="Lightning" size={40} />
                </Tag>
                <Space direction="vertical">
                  <Text strong level={3}>
                    {descriptions?.advantages?.first?.title}
                  </Text>
                  <Text level={4}>{descriptions?.advantages?.first?.description}</Text>
                </Space>
              </Space>

              <Space size={20} wrap={0}>
                <Tag shadow={1} padding={20} radius={theme?.sizes?.radius * 2} backgroundcolor={theme?.colors?.white}>
                  <IconCustom color={theme?.colors?.primary} iconName="Star" size={40} />
                </Tag>
                <Space direction="vertical">
                  <Text strong level={3}>
                    {descriptions?.advantages?.second?.title}
                  </Text>
                  <Text level={4}>{descriptions?.advantages?.second?.description}</Text>
                </Space>
              </Space>

              <Space size={20} wrap={0}>
                <Tag shadow={1} padding={20} radius={theme?.sizes?.radius * 2} backgroundcolor={theme?.colors?.white}>
                  <IconCustom color={theme?.colors?.primary} iconName="Gear" size={40} />
                </Tag>
                <Space direction="vertical">
                  <Text strong level={3}>
                    {descriptions?.advantages?.third?.title}
                  </Text>
                  <Text level={4}>{descriptions?.advantages?.third?.description}</Text>
                </Space>
              </Space>
            </Space>
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <CustomRow style={styles?.row} align="center">
              {descriptions?.indicators?.map((indicator, index) => (
                <CustomCol key={index} padding={paddingCol} xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                  <Space direction="vertical" size={0}>
                    <Title size={fontTitle} strong color={theme?.colors?.primary}>
                      {indicator?.value}
                    </Title>
                    <Text level={4}>{indicator?.description}</Text>
                  </Space>
                </CustomCol>
              ))}
            </CustomRow>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section backgroundcolor={theme?.colors?.backgroundSecondary} sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Image src={theme?.images?.noticia} width="100%" />
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space direction="vertical" size={20}>
              <Link color={theme?.colors?.textLight} level={4}>
                {descriptions?.news?.link}
              </Link>
              <Title level={4} strong>
                {descriptions?.news?.title}
              </Title>
              <Text level={4}>{descriptions?.news?.description}</Text>
              <Space>
                <ButtonPrimary
                  icon="Globe"
                  text={descriptions?.news?.firstButton}
                  onClick={() => window.open(Pdf)}
                  width={200}
                />
                <ButtonSecondary
                  icon="Newspaper"
                  onClick={() => window.open(descriptions?.news?.secondButtonLink)}
                  text={descriptions?.news?.secondButton}
                  width={200}
                />
              </Space>
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol}>
            <Space direction="vertical" align="center" size={40}>
              <Space direction="vertical" align="center">
                <Title align="center" strong level={4}>
                  {descriptions?.beforeAfter?.title}
                </Title>
                <Divider width={100} height={4} />
              </Space>
              <SliderImage width={700} image1={theme?.images?.after} image2={theme?.images?.before} />
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section backgroundcolor={theme?.colors?.backgroundSecondary} sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol}>
            <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
              {descriptions?.depositions?.label}
            </Text>
          </CustomCol>

          <CustomCol>
            <Carousel
              autoplay
              navigation={false}
              spaceBetween={20}
              slidesPerView={3}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                800: {
                  slidesPerView: 2,
                },
                1100: {
                  slidesPerView: 3,
                },
              }}>
              {descriptions?.depositions?.cards?.map((comment, index) => (
                <Card key={index} bodybgcolor={index === 0 ? theme?.colors?.primary : theme?.colors?.white} width={350}>
                  <Space direction="vertical" justify="space-between" height="100%" size={40}>
                    <Space width="100%" justify="space-between">
                      <IconCustom
                        size={fontTitle}
                        color={index === 0 ? theme?.colors?.white : theme?.colors?.primary}
                        iconName="Quotes"
                        weight="fill"
                      />
                      <Space direction="vertical" size={0} align="end">
                        <Text level={1} strong color={index === 0 ? theme?.colors?.white : theme?.colors?.primary}>
                          {comment?.title}
                        </Text>
                        <Text level={3} color={index === 0 ? theme?.colors?.white : theme?.colors?.primary}>
                          {comment?.subtitle}
                        </Text>
                      </Space>
                    </Space>
                    <Text
                      align="center"
                      color={index === 0 ? theme?.colors?.white : theme?.colors?.primary}
                      level={3}
                      strong>
                      {comment?.description}
                    </Text>
                    <Text />
                  </Space>
                </Card>
              ))}
            </Carousel>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol} sm={12}>
            <Space direction="vertical" size={0}>
              <Title strong level={5}>
                {descriptions?.buyNow?.subtitle}
              </Title>
              <Title font={fontTitle} strong>
                {descriptions?.buyNow?.title}
              </Title>
            </Space>
          </CustomCol>

          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space size={20} wrap={0}>
              <Tag shadow={1} padding={20} radius={theme?.sizes?.radius * 2} backgroundcolor={theme?.colors?.primary}>
                <IconCustom color={theme?.colors?.white} iconName="ShoppingCart" size={40} />
              </Tag>
              <Space>
                <Link color={theme?.colors?.text} strong level={3}>
                  {descriptions?.buyNow?.firstSection?.title}
                </Link>
                <Text level={4}>{descriptions?.buyNow?.firstSection?.description}</Text>
                <ButtonText
                  onClick={() => navigate("/contact")}
                  icon="ShoppingCart"
                  text={descriptions?.buyNow?.firstSection?.button}
                  width={180}
                />
              </Space>
            </Space>
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space size={20} wrap={0}>
              <Tag shadow={1} padding={20} radius={theme?.sizes?.radius * 2} backgroundcolor={theme?.colors?.white}>
                <IconCustom color={theme?.colors?.primary} iconName="ShoppingBagOpen" size={40} />
              </Tag>
              <Space>
                <Link color={theme?.colors?.text} strong level={3}>
                  {descriptions?.buyNow?.secondSection?.title}
                </Link>
                <Text level={4}>{descriptions?.buyNow?.secondSection?.description}</Text>
                <ButtonText
                  onClick={() => window.open(descriptions?.buyNow?.secondSection?.buttonLink)}
                  icon="ShoppingBagOpen"
                  text={descriptions?.buyNow?.secondSection?.button}
                  width={180}
                />
              </Space>
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section backgroundcolor={theme?.colors?.backgroundSecondary} sectionStyle={styles?.section}>
        <CustomRow style={styles?.row} align="center">
          <CustomCol padding={paddingCol} sm={12}>
            <Space direction="vertical" align="center">
              <Title align="center" strong level={4}>
                {descriptions?.trainings?.title}
              </Title>
              <Divider width={100} height={4} />
            </Space>
          </CustomCol>

          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Youtube src={theme?.videos?.video1} width={"100%"} height={400} />
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space direction="vertical" size={20}>
              <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
                {descriptions?.trainings?.firstVideo?.label}
              </Text>
              <Title level={4} strong>
                {descriptions?.trainings?.firstVideo?.title}
              </Title>

              <Text level={4}>{descriptions?.trainings?.firstVideo?.description}</Text>
            </Space>
          </CustomCol>

          <Divider color={theme?.colors?.backgroundPrimary} />

          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Space direction="vertical" size={20}>
              <Text strong letterSpacing={15} color={theme?.colors?.primary} level={5} lineright="true">
                {descriptions?.trainings?.secondVideo?.label}
              </Text>
              <Title level={4} strong>
                {descriptions?.trainings?.secondVideo?.title}
              </Title>

              <Text level={4}>{descriptions?.trainings?.secondVideo?.description}</Text>
            </Space>
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Youtube src={theme?.videos?.video2} width={"100%"} height={400} />
          </CustomCol>
        </CustomRow>
      </Section>
      <Footer />
    </React.Fragment>
  );
};

export default ProductFitBox;
