import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Carousel = ({ slidesPerView, spaceBetween, children, breakpoints, navigation, autoplay }) => {
  const slides = React.Children.toArray(children);

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      navigation={navigation ?? true}
      loop={true}
      autoplay={
        autoplay && {
          delay: 4000,
        }
      }
      pagination={{ clickable: true }}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView ?? 1}
      breakpoints={breakpoints}>
      {slides?.map((item, index) => (
        <SwiperSlide key={index}>{item}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export { Carousel };
