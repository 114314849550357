import styled from "styled-components";

const ImageStyled = styled.img`
  object-fit: ${({ resize }) => resize ?? "contain"};
  width: ${({ width }) => width ?? "auto"};
  height: ${({ height }) => height ?? "auto"};
  max-width: ${({ maxwidth }) => maxwidth ?? "auto"};
  max-height: ${({ maxheight }) => maxheight ?? "auto"};
  position: relative;

  /* transition: transform 0.8s ease;

  &:hover {
    transform: scale(1.05);
  } */
`;
export { ImageStyled };
