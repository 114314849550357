import { Form, Input, Row as AntdRow, Col as AntdCol, Select, Checkbox } from "antd";
import { useState } from "react";
import { estados_options, found_options, need_options } from "../../constants/theme";
import { ButtonPrimary } from "../Buttons";
import { Space } from "../Space";
import { validateCpfCnpj } from "../../helpers/validates";
import { CustomInputMask } from "../InputMask";
import emailjs from "@emailjs/browser";
import IconCustom from "../Icon";
import { Text, Title } from "../Typography";
import { useTheme } from "styled-components";
const { TextArea } = Input;

const validaCpfCnpj = (_, value) => {
  const cpf_cnpj = value?.toString()?.replace(/[^0-9]/g, "");
  if (value && !validateCpfCnpj(cpf_cnpj)) {
    return Promise.reject("CPF ou CNPJ inválido!");
  } else {
    return Promise.resolve();
  }
};

const validaCelular = (_, value) => {
  const celular = value?.toString()?.replace(/[^0-9]/g, "");
  if (celular && celular?.length < 10) {
    return Promise.reject("Celular inválido!");
  } else {
    return Promise.resolve();
  }
};

function objectToForm(object) {
  var form = document.createElement("form");
  for (var key in object) {
    if (object.hasOwnProperty(key)) {
      var input = document.createElement("input");
      input.type = "text";
      input.name = key;
      input.value = object[key];
      form.appendChild(input);
    }
  }
  return form;
}

const rules = {
  nome: [{ required: true, message: "Informe o nome!" }],
  estado: [{ required: true, message: "Selecione o estado!" }],
  cidade: [{ required: true, message: "Informe a cidade!" }],
  email: [
    { required: true, message: "Informe o e-mail!" },
    { type: "email", message: "E-mail inválido!" },
  ],
  celular: [{ required: true, message: "Informe o celular!" }, { validator: validaCelular }],
  como_encontrou: [{ required: true, message: "Selecione uma opção!" }],
  necessidade: [{ required: true, message: "Selecione uma opção!" }],
  observacoes: [{ required: true, message: "Informe as observacoes!" }],
  cpf_cnpj: [{ required: true, message: "Informe o CPF!" }, { validator: validaCpfCnpj }],
};

const FormContact = () => {
  const [loading, setLoading] = useState(false);
  const [etapa, setEtapa] = useState(1);
  const theme = useTheme();

  const sendEmail = async (template) => {
    setLoading(true);
    await emailjs.sendForm("service_oq89n5o", "template_szaoup9", template, "KAuGyvuhBXHnKAaTF").then(
      (result) => {
        setEtapa(2);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );

    setLoading(false);
  };

  const onSubmit = async (formulario) => {
    const template_params = {
      nome: formulario?.nome ?? "",
      cpf_cnpj: formulario?.cpf_cnpj ?? "",
      cidade: formulario?.cidade ?? "",
      estado: formulario?.estado ?? "",
      email: formulario?.email ?? "",
      celular: formulario?.celular ?? "",
      como_encontrou: formulario?.como_encontrou ?? "",
      necessidade: formulario?.necessidade ?? "",
      observacoes: formulario?.observacoes ?? "",
      receber_noticias: formulario?.receber_noticias ? "Sim" : "Não",
    };

    var myForm = objectToForm(template_params);
    sendEmail(myForm);
  };

  if (etapa === 2) {
    return (
      <Space direction="vertical" justify="center" align="center" size={20}>
        <IconCustom size={100} color={theme?.colors?.green} iconName="CheckCircle" />
        <Title level={2} strong={1} align="center">
          Formulário enviado com sucesso!
        </Title>
        <Text level={2}>Em breve entraremos em contato com você.</Text>
        <ButtonPrimary onClick={() => setEtapa(1)} iconDirection="left" icon="ArrowBendDownLeft" text="Voltar" />
      </Space>
    );
  }

  return (
    <Form layout="vertical" autoComplete="off" onFinish={onSubmit}>
      <AntdRow gutter={24}>
        <AntdCol xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Nome" name="nome" rules={rules?.nome} hasFeedback>
            <Input size="large" disabled={loading} placeholder="Informe o nome" />
          </Form.Item>
        </AntdCol>
        <AntdCol xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="CPF/CPNJ (Opcional)" name="cpf_cnpj" rules={rules?.cpf_cnpj} hasFeedback>
            <CustomInputMask maskType="cpf_cnpj" size="large" disabled={loading} placeholder="Informe o CPF/CNPJ" />
          </Form.Item>
        </AntdCol>
        <AntdCol xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Estado" name="estado" rules={rules?.estado} hasFeedback>
            <Select size="large" placeholder="Selecione o estado" disabled={loading} options={estados_options} />
          </Form.Item>
        </AntdCol>
        <AntdCol xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Cidade" name="cidade" rules={rules?.cidade} hasFeedback>
            <Input size="large" disabled={loading} placeholder="Informe a cidade" />
          </Form.Item>
        </AntdCol>
        <AntdCol xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="E-mail" name="email" rules={rules?.email} hasFeedback>
            <Input size="large" disabled={loading} placeholder="Informe o e-mail" />
          </Form.Item>
        </AntdCol>
        <AntdCol xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Celular" name="celular" rules={rules?.celular} hasFeedback>
            <CustomInputMask maskType="celular" size="large" disabled={loading} placeholder="Informe o celular" />
          </Form.Item>
        </AntdCol>
        <AntdCol xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="Como nos encontrou?" name="como_encontrou" rules={rules?.como_encontrou} hasFeedback>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              virtual={false}
              size="large"
              placeholder="Selecione uma opção"
              disabled={loading}
              options={found_options}
            />
          </Form.Item>
        </AntdCol>
        <AntdCol xs={24} sm={24} md={12} lg={12} xl={12}>
          <Form.Item label="O que deseja?" name="necessidade" rules={rules?.necessidade} hasFeedback>
            <Select
              getPopupContainer={(trigger) => trigger.parentElement}
              virtual={false}
              size="large"
              placeholder="Selecione uma opção"
              disabled={loading}
              options={need_options}
            />
          </Form.Item>
        </AntdCol>
        <AntdCol span={24}>
          <Form.Item label="Observações" name="observacoes" rules={rules?.observacoes} hasFeedback>
            <TextArea
              disabled={loading}
              autoSize={{ minRows: 3, maxRows: 8 }}
              size="large"
              placeholder="Informe as observações"
            />
          </Form.Item>
        </AntdCol>
        <AntdCol span={24}>
          <Form.Item name="receber_noticias" valuePropName="checked">
            <Checkbox disabled={loading}>Desejo receber notícias em meu e-mail</Checkbox>
          </Form.Item>
        </AntdCol>

        <AntdCol span={24}>
          <Form.Item>
            <ButtonPrimary width={120} icon="PaperPlaneRight" loading={loading} type="submit" text="Enviar" />
          </Form.Item>
        </AntdCol>
      </AntdRow>
    </Form>
  );
};

export { FormContact };
