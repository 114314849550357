const Youtube = ({ width, height, src }) => {
  return (
    <iframe
      width={width}
      height={height}
      style={{ border: "none" }}
      src={src}
      title="YouTube video player"
      allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
  );
};

export { Youtube };
