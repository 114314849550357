import { LinkStyled } from "./styles";

const Link = ({ underline, strong, level, color, children, italic, onClick, disabled, style, target, href }) => {
  return (
    <LinkStyled
      target={target ?? "_blank"}
      onClick={onClick}
      href={href}
      disabled={disabled}
      style={style}
      level={level}
      color={color}
      underline={underline?.toString()}
      strong={strong?.toString()}
      italic={italic?.toString()}>
      {children}
    </LinkStyled>
  );
};

export { Link };
