import styled from "styled-components";

const CardContainerStyled = styled.div`
  box-shadow: ${({ theme }) => theme?.colors?.shadow} 0px 13px 27px -5px,
    ${({ theme }) => theme?.colors?.shadow} 0px 8px 16px -8px;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  border-radius: ${({ theme }) => theme?.sizes?.radius * 2}px;
  background-color: ${({ theme }) => theme?.colors?.backgroundSecondary};
  padding: ${({ padding }) => padding}px;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const CardBodyStyled = styled.div`
  padding: ${({ theme }) => theme?.sizes?.padding * 2 ?? 0}px;
  border-radius: ${({ theme, bodyradius }) => (bodyradius ? theme?.sizes?.radius * 2 : 0)}px;
  width: 100%;
  position: relative;
  background-color: ${({ theme, bodybgcolor }) => bodybgcolor ?? theme?.colors?.primary};
  flex: 1;
`;

const CardImageStyled = styled.div`
  width: 100%;
  padding-block: ${({ theme }) => theme?.sizes?.padding}px;
`;

export { CardContainerStyled, CardBodyStyled, CardImageStyled };
