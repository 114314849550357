import { Col, Row } from "react-grid-system";

const CustomRow = (props) => {
  const inlineStyles = {
    margin: 0,
    alignItems: props?.align ?? "normal",
    justifyContent: props?.justify ?? "normal",
    ...props.style,
  };

  return (
    <Row {...props} style={inlineStyles}>
      {props?.children}
    </Row>
  );
};

const CustomCol = (props) => {
  const inlineStyles = {
    padding: props?.padding ?? 0,
    ...props.style,
  };

  return (
    <Col {...props} style={inlineStyles}>
      {props?.children}
    </Col>
  );
};

export { CustomRow, CustomCol };
