import { SpaceStyled } from "./styles";

const Space = ({ width, height, children, direction, size, style, wrap = 1, align, justify }) => {
  return (
    <SpaceStyled
      height={height}
      width={width}
      size={size}
      direction={direction}
      align={align}
      justify={justify}
      style={style}
      wrap={wrap}>
      {children}
    </SpaceStyled>
  );
};

export { Space };
