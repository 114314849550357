import { useTheme } from "styled-components";
import { HeaderStyled, HeaderMenuContainerStyled, HeaderMenuItemStyled } from "./styles";
import { ButtonPrimary } from "../Buttons";
import { Image } from "../Image";
import { Drawer } from "antd";
import { Link } from "../Link";
import { Visible } from "react-grid-system";
import { useState } from "react";
import { Space } from "../Space";
import { useNavigate } from "react-router-dom";
import descriptions from "../../constants/descriptions.json";

const Header = ({ activateKey }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <HeaderStyled>
      <Image width={150} src={theme?.images?.logo} />
      <Visible xxl xl lg>
        <HeaderMenuContainerStyled>
          <HeaderMenuItemStyled>
            <Link
              color={activateKey === "about" ? theme?.colors?.primary : null}
              strong
              onClick={() => navigate("/about")}>
              Sobre
            </Link>
          </HeaderMenuItemStyled>
          <HeaderMenuItemStyled>
            <Link
              color={activateKey === "product" ? theme?.colors?.primary : null}
              strong
              onClick={() => navigate("/")}>
              Fit Box FTTx
            </Link>
          </HeaderMenuItemStyled>
          <HeaderMenuItemStyled>
            <Link
              color={activateKey === "contact" ? theme?.colors?.primary : null}
              strong
              onClick={() => navigate("/contact")}>
              Contato
            </Link>
          </HeaderMenuItemStyled>
          <HeaderMenuItemStyled>
            <ButtonPrimary
              icon="ShoppingCartSimple"
              onClick={() => window.open(descriptions?.introduction?.secondButtonLink)}
            />
          </HeaderMenuItemStyled>
        </HeaderMenuContainerStyled>
      </Visible>

      <Visible md sm xs>
        <HeaderMenuItemStyled>
          <ButtonPrimary icon="List" onClick={() => setOpen(!open)} />
        </HeaderMenuItemStyled>
      </Visible>
      <Drawer width={250} title="" open={open} onClose={() => setOpen(!open)}>
        <Space direction="vertical" size={30}>
          <Link
            color={activateKey === "about" ? theme?.colors?.primary : null}
            strong
            onClick={() => navigate("/about")}>
            Sobre
          </Link>
          <Link color={activateKey === "product" ? theme?.colors?.primary : null} strong onClick={() => navigate("/")}>
            Fit Box FTTx
          </Link>
          <Link
            color={activateKey === "contact" ? theme?.colors?.primary : null}
            strong
            onClick={() => navigate("/contact")}>
            Contato
          </Link>
        </Space>
      </Drawer>
    </HeaderStyled>
  );
};

export { Header };
