import { useTheme } from "styled-components";
import { Image } from "../Image";
import { Link } from "../Link";
import { Space } from "../Space";
import { Text } from "../Typography";
import { Section } from "../Section";
import { CustomCol, CustomRow } from "../Grid";
import IconCustom from "../Icon";
import descriptions from "../../constants/descriptions.json";
import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const paddingCol = theme?.sizes?.padding * 2;

  const styles = {
    section: {
      paddingBlock: 80,
    },
  };

  return (
    <React.Fragment>
      <Section sectionStyle={styles?.section}>
        <CustomRow>
          <CustomCol padding={paddingCol} sm={12}>
            <Image width={150} src={theme?.images?.logo} />
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
            <Space direction="vertical">
              <Text strong>{descriptions?.footer?.company}</Text>
              <Text>{descriptions?.footer?.address}</Text>
              <Text>{descriptions?.footer?.phone}</Text>
            </Space>
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
            <Space direction="vertical">
              <Text strong>Acesse</Text>
              <Link onClick={() => navigate("/")} color={theme?.colors?.text} level={3}>
                Início
              </Link>
              <Link onClick={() => navigate("/about")} color={theme?.colors?.text} level={3}>
                Sobre nós
              </Link>
              <Link onClick={() => navigate("/contact")} color={theme?.colors?.text} level={3}>
                Contato
              </Link>
            </Space>
          </CustomCol>
          <CustomCol padding={paddingCol} xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
            <Space direction="vertical">
              <Text strong>Redes Sociais</Text>
              <Link href={descriptions?.socialLinks?.instagram} color={theme?.colors?.text} level={3}>
                <IconCustom size={theme?.sizes?.title5} iconName="InstagramLogo" weight="regular" />
                {descriptions?.footer?.instagram}
              </Link>
              <Link href={descriptions?.socialLinks?.facebook} color={theme?.colors?.text} level={3}>
                <IconCustom size={theme?.sizes?.title5} iconName="FacebookLogo" weight="regular" />
                {descriptions?.footer?.facebook}
              </Link>
              <Link href={descriptions?.socialLinks?.whatsapp} color={theme?.colors?.text} level={3}>
                <IconCustom size={theme?.sizes?.title5} iconName="WhatsappLogo" weight="regular" />
                {descriptions?.footer?.whatsapp}
              </Link>
              <Link color={theme?.colors?.text} level={3}>
                <IconCustom size={theme?.sizes?.title5} iconName="EnvelopeSimple" weight="regular" />
                {descriptions?.footer?.email}
              </Link>
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>

      <Section>
        <CustomRow>
          <CustomCol padding={paddingCol} sm={12}>
            <Space direction="vertical" align="center" style={styles?.alignCenter}>
              <Link level={5}>© 2023 Copyright - Kaue Thums</Link>
            </Space>
          </CustomCol>
        </CustomRow>
      </Section>
    </React.Fragment>
  );
};

export { Footer };
