import { CardContainerStyled, CardBodyStyled, CardImageStyled } from "./styles";
import { Image } from "../Image";

const Card = ({ children, width, image, bodybgcolor, bodyradius, padding }) => {
  return (
    <CardContainerStyled width={width} padding={padding}>
      {image && (
        <CardImageStyled>
          <Image src={image} width="100%" />
        </CardImageStyled>
      )}

      <CardBodyStyled bodybgcolor={bodybgcolor} bodyradius={bodyradius}>
        {children}
      </CardBodyStyled>
    </CardContainerStyled>
  );
};

export { Card };
