import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, Poppins,  Sans-Serif, Helvetica;
  }

  * {
    border: medium none;
    border-spacing: 0;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-indent: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #2b2b2b40;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme?.colors?.primary};
  }

  .swiper-button-next,
  .swiper-button-prev{
    color: ${({ theme }) => theme?.colors?.primary};
  }

  .swiper-pagination-bullet-active{
    background-color: ${({ theme }) => theme?.colors?.primary};
  } 

  .swiper-wrapper{
    padding-block: ${({ theme }) => theme?.sizes?.padding * 4}px;
  }

  .swiper-slide{
    display: flex;
    justify-content: center;
    height: auto;
  }
`;

export default GlobalStyle;
