import { SectionStyled, SectionContentStyled } from "./styles";

const Section = ({ backgroundcolor, children, height, style, sectionStyle, minheight }) => {
  return (
    <SectionStyled height={height} style={style} backgroundcolor={backgroundcolor}>
      <SectionContentStyled minheight={minheight} style={sectionStyle}>
        {children}
      </SectionContentStyled>
    </SectionStyled>
  );
};

export { Section };
