import styled from "styled-components";

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  gap: ${({ theme }) => theme?.sizes?.padding}px;
  padding: ${({ theme }) => theme?.sizes?.padding}px;
`;

const HeaderMenuContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`;

const HeaderMenuItemStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export { HeaderStyled, HeaderMenuItemStyled, HeaderMenuContainerStyled };
