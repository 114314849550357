const theme = {
  colors: {
    primary: "#3E4095",
    secondary: "#00AFEF",
    text: "#323232",
    textLight: "#A3A3A3",
    white: "#FFFFFF",
    primaryLight: "#D7D8F7",
    backgroundPrimary: "#F2F2F2",
    backgroundSecondary: "#FFFFFF",
    shadow: "#b9b9b9",
    green: "#439d49",
  },

  sizes: {
    radius: 8,

    font1: 24,
    font2: 22,
    font3: 20,
    font4: 18,
    font5: 16,

    title1: 36,
    title2: 34,
    title3: 32,
    title4: 30,
    title5: 28,

    bold: 600,

    padding: 12,

    lineHeight: 1.5,
  },

  images: {
    logo: require("../assets/images/logo.png"),
    produto: require("../assets/images/produto.png"),
    modelo3d1: require("../assets/images/modelo3d1.png"),
    modelo3d2: require("../assets/images/modelo3d2.png"),
    modelo3d3: require("../assets/images/modelo3d3.png"),
    modelo1: require("../assets/images/modelo1.png"),
    modelo2: require("../assets/images/modelo2.png"),
    modelo3: require("../assets/images/modelo3.png"),
    noticia: require("../assets/images/noticia.jpeg"),
    company1: require("../assets/images/company1.jpeg"),
    company2: require("../assets/images/company2.jpeg"),
    company3: require("../assets/images/company3.png"),
    company4: require("../assets/images/company4.jpeg"),
    leandro: require("../assets/images/leandro.png"),
    leandroCompany: require("../assets/images/leandroCompany.png"),
    after: require("../assets/images/after.png"),
    before: require("../assets/images/before.png"),
  },
  videos: {
    video1: "https://www.youtube.com/embed/XtZC1xIR1zc?si=BBDB9QtYxBQZCftr",
    video2: "https://www.youtube.com/embed/TdwJ7cjRT4c?si=UfHjldKMFwfX1966",
  },
};

const estados_options = [
  { value: "Acre", label: "Acre" },
  { value: "Alagoas", label: "Alagoas" },
  { value: "Amapá", label: "Amapá" },
  { value: "Amazonas", label: "Amazonas" },
  { value: "Bahia", label: "Bahia" },
  { value: "Ceará", label: "Ceará" },
  { value: "Distrito Federal", label: "Distrito Federal" },
  { value: "Espírito Santo", label: "Espírito Santo" },
  { value: "Goías", label: "Goías" },
  { value: "Maranhão", label: "Maranhão" },
  { value: "Mato Grosso", label: "Mato Grosso" },
  { value: "Mato Grosso do Sul", label: "Mato Grosso do Sul" },
  { value: "Minas Gerais", label: "Minas Gerais" },
  { value: "Pará", label: "Pará" },
  { value: "Paraíba", label: "Paraíba" },
  { value: "Paraná", label: "Paraná" },
  { value: "Pernambuco", label: "Pernambuco" },
  { value: "Piauí", label: "Piauí" },
  { value: "Rio de Janeiro", label: "Rio de Janeiro" },
  { value: "Rio Grande do Norte", label: "Rio Grande do Norte" },
  { value: "Rio Grande do Sul", label: "Rio Grande do Sul" },
  { value: "Rondônia", label: "Rondônia" },
  { value: "Roraíma", label: "Roraíma" },
  { value: "Santa Catarina", label: "Santa Catarina" },
  { value: "São Paulo", label: "São Paulo" },
  { value: "Sergipe", label: "Sergipe" },
  { value: "Tocantins", label: "Tocantins" },
];

const found_options = [
  { value: "Google", label: "Google" },
  { value: "Link de um site", label: "Link de um site" },
  { value: "Indicação", label: "Indicação" },
  { value: "Dica de um cliente", label: "Dica de um cliente" },
  { value: "Redes Sociais", label: "Redes Sociais" },
];

const need_options = [
  { value: "Informações", label: "Informações" },
  { value: "Catálogo de produtos", label: "Catálogo de produtos" },
  { value: "Atendimento comercial", label: "Atendimento comercial" },
  { value: "Atendimento financeiro", label: "Atendimento financeiro" },
  { value: "Ser uma revenda", label: "Ser uma revenda" },
  { value: "Ser um representante", label: "Ser um representante" },
];

export { theme, estados_options, need_options, found_options };
