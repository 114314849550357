import styled from "styled-components";

const TagStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme, radius }) => radius ?? theme?.sizes?.radius}px;
  background-color: ${({ theme, backgroundcolor }) => backgroundcolor ?? theme?.colors?.primaryLight};
  padding: ${({ theme, padding }) => padding ?? theme?.sizes?.padding / 2}px;
  box-shadow: ${({ theme, shadow }) => (shadow ? theme?.colors?.shadow : "transparent")} 0px 13px 27px -5px,
    ${({ theme, shadow }) => (shadow ? theme?.colors?.shadow : "transparent")} 0px 8px 16px -8px;
`;
export { TagStyled };
