import { ButtonPrimaryStyled, ButtonSecondaryStyled, ButtonTextStyled } from "./styles";
import { Text } from "../Typography";
import { useTheme } from "styled-components";
import IconCustom from "../Icon";
import ClipLoader from "react-spinners/ClipLoader";

const ButtonPrimary = ({ text, iconDirection = "right", icon, width, onClick, disabled, loading, type, style }) => {
  const theme = useTheme();

  const loadingIcon = <ClipLoader color={theme?.colors?.white} loading={true} size={theme?.sizes?.font5} />;
  const renderIcon = !loading && icon && (
    <IconCustom iconName={icon} size={theme?.sizes?.font3} color={theme?.colors?.white} weight="regular" />
  );
  const rnderText = (
    <Text strong level={5} color={theme?.colors?.white}>
      {text}
    </Text>
  );

  return (
    <ButtonPrimaryStyled style={style} disabled={disabled || loading} width={width} onClick={onClick} type={type}>
      {iconDirection === "left" && renderIcon}
      {text && rnderText}
      {loading && loadingIcon}
      {iconDirection === "right" && renderIcon}
    </ButtonPrimaryStyled>
  );
};

const ButtonSecondary = ({ text, iconDirection = "right", icon, width, onClick, disabled, loading, type, style }) => {
  const theme = useTheme();

  const loadingIcon = <ClipLoader color={theme?.colors?.primary} loading={true} size={theme?.sizes?.font5} />;
  const renderIcon = !loading && icon && (
    <IconCustom iconName={icon} size={theme?.sizes?.font3} color={theme?.colors?.primary} weight="regular" />
  );
  const rnderText = (
    <Text strong level={5} color={theme?.colors?.primary}>
      {text}
    </Text>
  );
  return (
    <ButtonSecondaryStyled style={style} disabled={disabled || loading} width={width} onClick={onClick} type={type}>
      {iconDirection === "left" && renderIcon}
      {text && rnderText}
      {loading && loadingIcon}
      {iconDirection === "right" && renderIcon}
    </ButtonSecondaryStyled>
  );
};

const ButtonText = ({ text, iconDirection = "right", icon, width, onClick, disabled, loading, type, style, color }) => {
  const theme = useTheme();

  const loadingIcon = <ClipLoader color={color ?? theme?.colors?.primary} loading={true} size={theme?.sizes?.font5} />;
  const renderIcon = !loading && icon && (
    <IconCustom iconName={icon} size={theme?.sizes?.font3} color={color ?? theme?.colors?.primary} weight="regular" />
  );
  const rnderText = (
    <Text strong level={5} color={color ?? theme?.colors?.primary}>
      {text}
    </Text>
  );
  return (
    <ButtonTextStyled style={style} disabled={disabled || loading} width={width} onClick={onClick} type={type}>
      {iconDirection === "left" && renderIcon}
      {text && rnderText}
      {loading && loadingIcon}
      {iconDirection === "right" && renderIcon}
    </ButtonTextStyled>
  );
};

export { ButtonPrimary, ButtonSecondary, ButtonText };
