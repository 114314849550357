import { TagStyled } from "./styles";

const Tag = ({ backgroundcolor, children, padding, radius, shadow }) => {
  return (
    <TagStyled backgroundcolor={backgroundcolor} padding={padding} radius={radius} shadow={shadow}>
      {children}
    </TagStyled>
  );
};

export { Tag };
